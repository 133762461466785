/* eslint-disable security/detect-object-injection */
import * as React from 'react';
import { IMlCarouselViewProps } from './ml-carousel';

export default (props: IMlCarouselViewProps) => {
    const { slides, autoPlay, autoPlayInterval } = props;

    const [currentSlide, setCurrentSlide] = React.useState(0);

    const handleNextSlide = () => {
        if (currentSlide === slides.length - 1) {
            setCurrentSlide(0);
            return;
        }
        setCurrentSlide(currentSlide + 1);
    };

    const handlePreviousSlide = () => {
        if (currentSlide === 0) {
            setCurrentSlide(slides.length - 1);
            return;
        }
        setCurrentSlide(currentSlide - 1);
    };

    const handleIndicatorClick = (index: number) => {
        setCurrentSlide(index);
    };

    React.useEffect(() => {
        if (!autoPlay) {
            return;
        }
        const interval = setInterval(() => {
            handleNextSlide();
        }, autoPlayInterval * 1000);
        return () => clearInterval(interval);
    });

    return (
        <div className='carousel'>
            <div className='inner-carousel'>
                {slides &&
                    slides.map((slide, index) => {
                        return (
                            <div
                                key={index}
                                className={`carousel-item`}
                                style={{ backgroundImage: `url(${slides[currentSlide].image?.src})` }}
                            >
                                <div
                                    className={`carousel-item-content ${slides[currentSlide].contentPosition} ${
                                        slides[currentSlide].enableBackgroundBlur ? 'filterBlur' : ''
                                    }`}
                                >
                                    <h1 style={{ color: slides[currentSlide].titleColor }}>{slides[currentSlide].title}</h1>
                                    <p style={{ color: slides[currentSlide].descriptionColor }}>{slides[currentSlide].description}</p>
                                    {slides[currentSlide].primaryCtaButton && slides[currentSlide].primaryCtaButton?.text?.length !== 0 && (
                                        <a
                                            className='msc-cta__primary'
                                            role='button'
                                            title={slides[currentSlide].primaryCtaButton?.text}
                                            href={slides[currentSlide].primaryCtaButton?.link?.destinationUrl}
                                            aria-label={slides[currentSlide].primaryCtaButton?.text}
                                            target='_blank'
                                            data-exp-event-id='..Shop Now.click'
                                            rel='noreferrer'
                                        >
                                            <div>{slides[currentSlide].primaryCtaButton?.text}</div>
                                        </a>
                                    )}

                                    {slides[currentSlide].secondaryCtaButton &&
                                        slides[currentSlide].secondaryCtaButton?.text?.length !== 0 && (
                                            <a
                                                className='msc-cta__secondary'
                                                role='button'
                                                title={slides[currentSlide].secondaryCtaButton?.text}
                                                href={slides[currentSlide].secondaryCtaButton?.link?.destinationUrl}
                                                aria-label={slides[currentSlide].secondaryCtaButton?.text}
                                                target='_blank'
                                                data-exp-event-id='..Learn more.click'
                                                rel='noreferrer'
                                            >
                                                <div>{slides[currentSlide].secondaryCtaButton?.text}</div>
                                            </a>
                                        )}
                                </div>
                                <div className='left' onClick={handlePreviousSlide}>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                        <g fill='none' fillRule='evenodd'>
                                            <path d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z' />
                                            <path
                                                fill='currentColor'
                                                d='M7.94 13.06a1.5 1.5 0 0 1 0-2.12l5.656-5.658a1.5 1.5 0 1 1 2.121 2.122L11.121 12l4.596 4.596a1.5 1.5 0 1 1-2.12 2.122L7.938 13.06Z'
                                            />
                                        </g>
                                    </svg>
                                </div>
                                <div className='right' onClick={handleNextSlide}>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                        <g transform='rotate(180 12 12)'>
                                            <g fill='none' fillRule='evenodd'>
                                                <path d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z' />
                                                <path
                                                    fill='currentColor'
                                                    d='M7.94 13.06a1.5 1.5 0 0 1 0-2.12l5.656-5.658a1.5 1.5 0 1 1 2.121 2.122L11.121 12l4.596 4.596a1.5 1.5 0 1 1-2.12 2.122L7.938 13.06Z'
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className='carousel-indicator-wrapper'>
                                    {slides.map((_, index) => {
                                        return (
                                            <div
                                                key={index}
                                                onClick={() => handleIndicatorClick(index)}
                                                className={`carousel-indicator ${currentSlide === index ? 'active' : ''}`}
                                            ></div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
